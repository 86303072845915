import React from "react";
import {FormBuilder} from "@jaguardesignstudio/silentgrowl-form-builder-react";
import RegistrationPageSignifiers from "./RegistrationPageSignifiers";
import RegistrationCheckBoxField from "./RegistrationCheckBoxField";
import RegistrationRadioField from "./RegistrationRadioField";
import RegistrationTicketField from "./RegistrationTicketField";

class RegistrationFormWrapper extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }

  callback(json) {
    console.log('reg submit complete');
    console.log(json);
  }

  getReplacableStrings() {
    const { participant } = this.props;
    return [
      {key: '*|EMAIL|*', value: participant.email }
    ]
  }

  render() {
    const { package_info, ticket_info, participant, form } = this.props;
    // console.log(ticket_info);
    // console.log(participant);
    // console.log(form);
    return (
      <FormBuilder
        key="registration-form"
        components={{
          radiofield: RegistrationRadioField,
          pageSignifiers: RegistrationPageSignifiers,
          ticketfield: RegistrationTicketField
        }}
        // ticket_info={package_info}
        ticket_info={ticket_info}
        participant={participant}
        form={form}
        gid={form.gid}
        replacableStrings={
          this.getReplacableStrings()
        }
        submitUrl={'/registration'}
        submitCallback={this.callback}
        type={'registration'}
        use_legacy_ticketing={false}
        submit_spinner={true}
      />
    );
  }
}

export default RegistrationFormWrapper;
